
import { defineComponent } from 'vue';
import {
    DocumentationInterface,
    fetchDocumentation,
    REQUEST,
    TApiType,
    BASETYPES,
    BASETYPES_ARR
} from '@/services/documentation';

export default defineComponent({
    name: 'Documentation',
    data() {
        return {
            documentation: {} as DocumentationInterface,
            expanded: [] as boolean[]
        };
    },
    computed: {
        routes(): string[] {
            return Object.keys(this.documentation);
        }
    },
    methods: {
        reqtype(route: string): REQUEST {
            return this.documentation[route].requestType;
        },
        output(route: string): TApiType {
            return this.documentation[route].output;
        },
        input(route: string): TApiType | undefined {
            return this.documentation[route].input;
        },
        descr(route: string): string {
            return this.documentation[route].description;
        },
        rowClass(route: string): string {
            const reqtype = this.reqtype(route);
            switch (reqtype) {
                case REQUEST.GET:
                    return 'row__title--get';
                case REQUEST.PUT:
                    return 'row__title--put';
                case REQUEST.DELETE:
                    return 'row__title--delete';
                case REQUEST.POST:
                    return 'row__title--post';
            }
        },
        badgeClass(route: string): string {
            const reqtype = this.reqtype(route);
            switch (reqtype) {
                case REQUEST.GET:
                    return 'route__type--get';
                case REQUEST.PUT:
                    return 'route__type--put';
                case REQUEST.DELETE:
                    return 'route__type--delete';
                case REQUEST.POST:
                    return 'route__type--post';
            }
        },
        expandedClass(idx: number): string {
            return this.expanded[idx] ? 'icon-angle-down' : 'icon-angle-right';
        },
        toggle(idx: number) {
            this.expanded[idx] = !this.expanded[idx];
        },
        // eslint-disable-next-line
        parseNestedJSON(objStr: string): any {
            const obj = JSON.parse(objStr);
            const keys = Object.keys(obj);
            keys.forEach(k => {
                if (BASETYPES_ARR.indexOf(obj[k].type as BASETYPES) === -1){
                    obj[k].type = this.parseNestedJSON(obj[k].type);
                }
            });

            return obj;
        },
        pretty(routeType: string | undefined){
            if(!routeType) return '';


            const arrIdentifier = routeType.endsWith('[]');
            if(arrIdentifier) routeType = routeType.replace('[]', '');

            const jsonObj = this.parseNestedJSON(routeType);

            const noHTMLentites = JSON.stringify(jsonObj, undefined, 2)
                .replaceAll(/&/g, '&amp;')
                .replaceAll(/</g, '&lt;')
                .replaceAll(/>/g, '&gt;');
            let pretty = noHTMLentites.replace(
                /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
                (match) => {
                    let jsonClass = 'json';
                    if (/^"/.test(match)) {
                        if (/:$/.test(match)) {
                            if (/"type"/.test(match) || (/"required"/.test(match))){
                                jsonClass += ' json--seckey';
                            }
                            else jsonClass += ' json--primkey';
                        }
                    } 
                    if (/"Boolean"/.test(match)) {
                        jsonClass += ' json--boolean';
                    } 
                    else if (/"Number"/.test(match)) {
                        jsonClass += ' json--number';
                    }
                    else if (/"String"/.test(match)) {
                        jsonClass += ' json--string';
                    }
                    else if (/"Array"/.test(match)) {
                        jsonClass += ' json--array';
                    }
                    return `<span class="${jsonClass}">${match.replaceAll('"', '')}</span>`;
                }
            );

            if(arrIdentifier) pretty += "[]";

            return pretty;
        }
    },
    async mounted() {
        this.documentation = await fetchDocumentation();
        this.expanded = this.routes.map(() => false); // all collapsed by default
    }
});
